<template>
    <div class="prepare-lesson-container">
        <div class="prepare-lesson-banner">
            <div class="prepare-lesson-banner-text">
                <div class="prepare-lesson-banner-text-top">优学派备授课云平台解决方案</div>
                <div class="prepare-lesson-banner-text-bottom">
                    <span v-for="(item, index) in texts" :key="index">{{item}}</span>
                </div>
            </div>
        </div>
        <div class="content">
            <div
                class="img"
                :class="{'no-padding-bottom': [7].includes(item)}"
                v-for="item in 7"
                :key="item"
                :style="getStyle(item)"
            >
                <img :src="require(`./images/icon_content_p_${item}.png`)" >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PrepareLesson',
    data () {
        return {
            texts: ['教学专属硬件', '移动教学', '科学管理', '智能化服务'],
        };
    },
    methods: {
        getStyle (index) {
            let imgUrl = require(`./images/icon_bg_p_${index}.png`);
            return { 'background-image': 'url(' + imgUrl + ')' };
        },
    },
};
</script>
<style scoped lang="scss">
    .prepare-lesson-container {
        .prepare-lesson-banner {
            width: 100%;
            height: 375px;
            background-image: url('./images/icon_banner_p_l.png');
            background-repeat: no-repeat;
            // background-size: 2293px 375px;
            background-position: center;
            display: flex;
            align-items: center;
            &-text {
                margin-bottom: 60px;
                margin-left: 15%;
                &-top {
                    font-size:40px;
                    color:#ffffff;
                    line-height:72px;
                    margin-bottom: 28px;
                }
                &-bottom {
                    font-size:22px;
                    color:#fbfbf4;
                    line-height:45px;
                    span {
                        margin-right: 20px;
                    }
                }
            }
        }
        .content {
            .img {
                text-align: center;
                padding: 60px 0;
                &.no-padding-bottom {
                    padding-bottom: 0px;
                }
                img {
                    max-width: 1024px;
                }
            }
        }
    }
</style>
